import { useId } from 'react'
import { Container } from './Container.jsx'

function InsightsIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px" fill="#000000"><path d="m136-240-56-56 212-212q35-35 85-35t85 35l46 46q12 12 28.5 12t28.5-12l178-178H640v-80h240v240h-80v-103L621-405q-35 35-85 35t-85-35l-47-47q-11-11-28-11t-28 11L136-240Z"/></svg>
  )
}

function GoalsIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px" fill="#000000"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-80q-100 0-170-70t-70-170q0-100 70-170t170-70q100 0 170 70t70 170q0 100-70 170t-170 70Zm0-80q66 0 113-47t47-113q0-66-47-113t-113-47q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0-80q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Z"/></svg>
  )
}

function CustomizeIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px" fill="#000000"><path d="M120-840h320v320H120v-320Zm80 80v160-160Zm320-80h320v320H520v-320Zm80 80v160-160ZM120-440h320v320H120v-320Zm80 80v160-160Zm440-80h80v120h120v80H720v120h-80v-120H520v-80h120v-120Zm-40-320v160h160v-160H600Zm-400 0v160h160v-160H200Zm0 400v160h160v-160H200Z"/></svg>
  )
}

function DeviceLockIcon(props) {
  return (
    <svg viewBox="0 0 32 32" aria-hidden="true" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4a4 4 0 014-4h14a4 4 0 014 4v10h-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 002 2h5v2H9a4 4 0 01-4-4V4z"
        fill="#737373"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 19.5a3.5 3.5 0 117 0V22a2 2 0 012 2v6a2 2 0 01-2 2h-7a2 2 0 01-2-2v-6a2 2 0 012-2v-2.5zm2 2.5h3v-2.5a1.5 1.5 0 00-3 0V22z"
        fill="#171717"
      />
    </svg>
  )
}

const features = [
  {
    name: 'Actionable insights',
    description: 'By uncovering hidden trends and relationships in your health data, you can discover what steps need to be taken to improve any desired aspect of your health.',
    icon: InsightsIcon,
  },
  {
    name: 'Goal oriented',
    description: 'Coefficients allows you to set goals for any data type, enabling you to easily visualize your progress and identify the factors helping you reach them.',
    icon: GoalsIcon,
  },
  {
    name: 'Highly customizable',
    description: 'Whether you want a basic habit tracker or an in-depth data analysis tool, Coefficients offers the flexibility to fit your needs. Nearly every aspect of the experience is adjustable, from chart types, widget sizes, and layouts to how your insights are generated.',
    icon: CustomizeIcon,
  },
  {
    name: 'Encrypted and secure',
    description: 'Health data is both personal and highly sensitive. Coefficients neither stores nor sends your health data off your device, and it does not collect any information about you. All data on your device is encrypted using industry-standard AES-256.',
    icon: DeviceLockIcon,
  },
]

export function SecondaryFeatures() {
  return (
    <section
      id="secondary-features"
      aria-label="Product features"
      className="py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl sm:text-center">
        <p class="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        Analyze your health data, discover <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-orange-500 decoration-blue-500/50">actionable</span> relationships
        </p>

        <p class="mt-6 text-lg leading-8 text-gray-600">
          Discover emerging trends and relationships, set goals for anything, and fully customize your experience.
        </p>

        </div>
        <div className="mt-16 sm:mt-20 flex justify-center">
          <ul
            role="list"
            className="grid grid-cols-1 gap-6 text-sm sm:grid-cols-2"
          >
            {features.map((feature) => (
              <li
              key={feature.name}
              className="rounded-2xl border border-gray-200 p-8 max-w-md"
            >
                <feature.icon className="h-8 w-8" />
                <h3 className="mt-6 font-semibold text-gray-900">
                  {feature.name}
                </h3>
                <p className="mt-2 text-gray-700">{feature.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </section>
  )
}

// const SecondaryFeatures = () => {
//     return (
//   <div class="bg-white flex items-center justify-center min-h-screen">
//     <div class="mx-auto max-w-7xl px-6 lg:px-8">
//       <div class="mx-auto max-w-2xl lg:text-center">

//         {/* <h2 class="text-base font-semibold leading-7 text-indigo-600">
//           Top text
//         </h2> */}

        // <p class="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        // Analyze your health data, discover <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-orange-500 decoration-blue-500/50">actionable</span> relationships
        // </p>

        // <p class="mt-6 text-lg leading-8 text-gray-600">
        //   Discover emerging trends and relationships, set goals for anything, and fully customize your experience.
        // </p>
//       </div>
      
//       <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
//         <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
//           <div class="relative pl-16">

//             <dt class="text-base font-semibold leading-7 text-gray-900">
  
//             <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-black">
//                 <svg
//                   class="h-6 w-6 text-white"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke-width="1.5"
//                   stroke="currentColor"
//                   aria-hidden="true"
//                 >
//                   <path
//                     stroke-linecap="round"
//                     stroke-linejoin="round"
//                     d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
//                   />
//                 </svg>
//               </div>
//               Actionable insights
//             </dt>

//             <dd class="mt-2 text-base leading-7 text-gray-600">
//             By uncovering hidden trends and relationships in your health data, you can discover what steps need to be taken to improve any desired aspect of your health.
//             </dd>

//           </div>

//           <div class="relative pl-16">
//             <dt class="text-base font-semibold leading-7 text-gray-900">

//             <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-black">
//                 <svg
//                   class="h-6 w-6 text-white"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke-width="1.5"
//                   stroke="currentColor"
//                   aria-hidden="true"
//                 >
//                   <path
//                     stroke-linecap="round"
//                     stroke-linejoin="round"
//                     d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
//                   />
//                 </svg>
//               </div>
//               Goal Oriented
//             </dt>

//             <dd class="mt-2 text-base leading-7 text-gray-600">
//             Coefficients allows you to set goals for any data type, enabling you to easily visualize your progress and identify the factors helping you reach them.
//             </dd>

//           </div>

//           <div class="relative pl-16">

//             <dt class="text-base font-semibold leading-7 text-gray-900">
//               <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
//                 <svg
//                   class="h-6 w-6 text-white"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke-width="1.5"
//                   stroke="currentColor"
//                   aria-hidden="true"
//                 >
//                   <path
//                     stroke-linecap="round"
//                     stroke-linejoin="round"
//                     d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
//                   />
//                 </svg>
//               </div>
//               Highly customizable
//             </dt>

//             <dd class="mt-2 text-base leading-7 text-gray-600">
//               Quisque est vel vulputate cursus. Risus proin diam nunc commodo.
//               Lobortis auctor congue commodo diam neque.
//             </dd>

//           </div>

//           <div class="relative pl-16">
//             <dt class="text-base font-semibold leading-7 text-gray-900">
//               <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
//                 <svg
//                   class="h-6 w-6 text-white"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke-width="1.5"
//                   stroke="currentColor"
//                   aria-hidden="true"
//                 >
//                   <path
//                     stroke-linecap="round"
//                     stroke-linejoin="round"
//                     d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33"
//                   />
//                 </svg>
//               </div>
//               Encrypted and secure
//             </dt>
//             <dd class="mt-2 text-base leading-7 text-gray-600">
//               Arcu egestas dolor vel iaculis in ipsum mauris. Tincidunt mattis
//               aliquet hac quis. Id hac maecenas ac donec pharetra eget.
//             </dd>
//           </div>

//         </dl>
//       </div>
//     </div>
//   </div>
// );
// };

export default SecondaryFeatures;