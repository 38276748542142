import React from "react";

import Hero from "./components/Hero.jsx";
import SecondaryFeatures from "./components/SecondaryFeatures.jsx";
import { PrimaryFeatures } from "./components/PrimaryFeatures.jsx";
import { CallToAction } from "./components/CallToAction.jsx";

const Home = () => {
  return (
    <div>
      <Hero />

      <div id="features">
        <PrimaryFeatures />
      </div>
      <SecondaryFeatures />

      <CallToAction />
    </div>
  );
};

export default Home;
