// Contact.js
import React from "react";

import ContactForm from "./components/ContactForm";

const Contact = () => {
  return (
    <div>
      <ContactForm />
      {/* <p>Contact us at contact@example.com</p> */}
    </div>
  );
};

export default Contact;
