import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/Header";
import Home from "./Home";
import Contact from "./Contact";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />

        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
