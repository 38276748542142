import React from "react";
import { HashLink } from "react-router-hash-link";
import DashboardImage from "../assets/dashboard.png";

const Hero = () => {
  return (
    <div className="bg-white">
      <div className="relative isolate px-8 sm:px-12 lg:px-16 pt-8">
        <div className="mx-auto max-w-7xl py-12 sm:py-16 lg:py-20">
          <div className="flex flex-col lg:flex-row lg:items-center lg:gap-8">
            {/* Top Color Splash */}
            <div
              className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
              aria-hidden="true"
            >
              <div
                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-purple-500 to-orange-500 opacity-50 dark:opacity-70 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              ></div>
            </div>

            {/* Bottom Color Splash */}
            <div
              className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
              aria-hidden="true"
            >
              <div
                className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-cyan-500 to-blue-500 opacity-50 dark:opacity-70 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              ></div>
            </div>

            <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-start text-center lg:text-left">
              <h1 className="text-balance text-4xl font-extrabold tracking-tight text-gray-900 sm:text-6xl">
                Data-Driven Health Insights
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Coefficients unlocks the power of your health data by providing
                highly customizable data visualization dashboards and insights
                that help you discern what factors are impacting your health and
                fitness.
              </p>
              <div className="mt-8 flex items-center gap-x-6">
                <a
                  href="https://apps.apple.com/app/coefficients/id6476617916"
                  className="block"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
                    alt="Download on the App Store"
                    className="w-[135px] h-auto"
                  />
                </a>

                {/* Using HashLink for smooth scroll */}
                <HashLink
                  smooth
                  to="#features"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Learn more <span aria-hidden="true">→</span>
                </HashLink>
              </div>
            </div>

            <div className="w-full lg:w-1/2 pt-8 sm:pt-12 lg:pt-4">
              <img
                src={DashboardImage}
                alt="Coefficients Dashboard"
                className="w-full max-w-[350px] h-auto mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
