import React from "react";

const Footer = () => {
  return (
    <footer className="flex justify-between items-center p-4 h-16 bg-white text-gray-400 border-t border-gray-300 mx-8">
      <p className="text-xs md:text-xs lg:text-sm">© Copyright 2024. All rights reserved.</p>
      <a
        href="https://x.com/CoefficientsApp"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center space-x-4 pr-6"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          className="w-6 h-6 md:w-8 md:h-8"
          fillRule="evenodd"
          clipRule="evenodd"
          baseProfile="basic"
          stroke="white"
          strokeWidth="0.5"
        >
          <path
            fill="#000000 dark:bg-black"
            fillRule="evenodd"
            d="M38,42H10c-2.209,0-4-1.791-4-4V10c0-2.209,1.791-4,4-4h28 c2.209,0,4,1.791,4,4v28C42,40.209,40.209,42,38,42z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#fff"
            d="M34.257,34h-6.437L13.829,14h6.437L34.257,34z M28.587,32.304h2.563L19.499,15.696h-2.563 L28.587,32.304z"
          ></path>
          <polygon fill="#fff" points="15.866,34 23.069,25.656 22.127,24.407 13.823,34"></polygon>
          <polygon fill="#fff" points="24.45,21.721 25.355,23.01 33.136,14 31.136,14"></polygon>
        </svg>
      </a>
    </footer>
  );
};

export default Footer;