import { Container } from "../components/Container";
import Icon from "../assets/CoefficientsIcon.png";

export function CallToAction() {
  return (
    <section
  id="get-free-shares-today"
  className="relative overflow-hidden bg-white pt-16 pb-24 sm:pt-16 sm:pb-28 flex items-center justify-center"
>
      <Container className="relative px-4 sm:px-10 lg:px-16 max-w-screen-lg mx-auto">
        <div className="flex flex-col items-center md:flex-row md:space-x-8">
          {/* Left-hand Column */}
          <div className="md:w-1/2 flex flex-col items-start text-left">
            <div className="mx-auto max-w-md md:mx-0 md:max-w-none w-full">
              <h2 className="text-4xl font-extrabold tracking-tight text-black sm:text-4xl">
                Coefficients is now available.
              </h2>
              <p className="mt-4 text-lg text-gray-600">
                Special thanks to everyone who participated in the public beta
              </p>
              <div className="mt-6 flex justify-center w-full">
                <a
                  href="https://apps.apple.com/app/coefficients/id6476617916"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
                    alt="Download on the App Store"
                    className="w-[135px] h-auto"
                  />
                </a>
              </div>
            </div>
          </div>

          {/* Right-hand Column with Icon */}
          <div className="mt-10 md:mt-0 md:w-1/2 flex justify-center">
            <img src={Icon} alt="Coefficients Icon" className="w-80 h-80" />
          </div>
        </div>
      </Container>
    </section>
  );
}
