import React, { useState } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    setStatus("Sending...");

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          name: formData.name,
          message: formData.message,
          reply_to: formData.email, 
        },
        process.env.REACT_APP_EMAILJS_KEY
      )
      .then((response) => {
        setStatus("Message sent successfully!");
        setFormData({ name: "", email: "", message: "" });
      })
      .catch((err) => {
        setStatus("Failed to send message. Please try again later.");
        console.error("Error:", err);
      });
  };

  const getStatusStyle = () => {
    if (status.includes("Sending")) {
      return "bg-gray-200 text-gray-700";
    } else if (status.includes("successfully")) {
      return "bg-green-200 text-green-700";
    } else if (status.includes("Failed")) {
      return "bg-red-200 text-red-700";
    }
    return "";
  };

  return (
    <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Have any{" "}
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-purple-500 decoration-blue-500/50">
            questions
          </span>
          ?
        </h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Send us a message and we'll get back to you shortly.
        </p>
      </div>

      {status && (
        <div
          className={`mt-4 mx-auto max-w-xl text-center py-3 px-4 rounded-lg ${getStatusStyle()}`}
        >
          {status}
        </div>
      )}

      <form onSubmit={sendEmail} className="mx-auto mt-8 max-w-xl sm:mt-10">
        <div className="grid grid-cols-1 gap-x-8 gap-y-6">
          <div className="sm:col-span-2">
            <label
              htmlFor="name"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-100 bg-white dark:bg-gray-800 shadow-md ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                required
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="email"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2.5">
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-100 bg-white dark:bg-gray-800 shadow-md ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                required
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="message"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Message
            </label>
            <div className="mt-2.5">
              <textarea
                name="message"
                id="message"
                rows="4"
                value={formData.message}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-100 bg-white dark:bg-gray-800 shadow-md ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                required
              ></textarea>
            </div>
          </div>
        </div>
        <div className="mt-10 flex justify-center">
          <button
            type="submit"
            className="w-1/4 rounded-md border border-black bg-white px-3.5 py-2.5 text-center text-sm font-semibold text-black shadow-sm hover:bg-black hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm; 