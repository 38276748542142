import React, { useState } from "react";
import { Link } from "react-router-dom"; 
import { HashLink } from "react-router-hash-link";
import Logo from "../assets/logo.svg";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav
        className="flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            {" "}
            {/* Use Link for routing to home */}
            <span className="sr-only">Coefficients</span>
            <img
              className="h-9 w-auto dark:invert"
              src={Logo}
              alt="Coefficients"
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          <Link
            to="/"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {" "}
            {/* Use Link for routing to home */}
            Home
          </Link>
          <HashLink
            smooth
            to="/#features" // Add the leading slash to navigate to the Home page first
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Features
          </HashLink>
          <Link
            to="/contact"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {" "}
            {/* Use Link for routing to contact */}
            Contact
          </Link>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
  <a href="https://apps.apple.com/app/coefficients/id6476617916" className="bg-black text-white px-4 py-2 rounded-lg text-sm font-semibold leading-6">
    Download <span aria-hidden="true">&rarr;</span>
  </a>
</div>
      </nav>

      {/* Mobile Menu */}
      {menuOpen && (
  <div className="lg:hidden" role="dialog" aria-modal="true">
    <div className="fixed inset-0 z-50"></div>
    <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
      <div className="flex items-center justify-between">
        <Link to="/" className="-m-1.5 p-1.5" onClick={() => setMenuOpen(false)}>
          <span className="sr-only">Coefficients</span>
          <img
            className="h-9 w-auto dark:invert"
            src={Logo}
            alt="Coefficients"
          />
        </Link>
        <button
          type="button"
          onClick={() => setMenuOpen(false)}
          className="-m-2.5 rounded-md p-2.5 text-gray-700"
        >
          <span className="sr-only">Close menu</span>
          <svg
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="mt-6 flow-root">
        <div className="-my-6 divide-y divide-gray-500/10">
          <div className="space-y-2 py-6 text-center">
            <Link
              to="/"
              className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              onClick={() => setMenuOpen(false)}
            >
              Home
            </Link>

            <HashLink
              smooth
              to="/#features"
              className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              onClick={() => setMenuOpen(false)}
            >
              Features
            </HashLink>

            <Link
              to="/contact"
              className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              onClick={() => setMenuOpen(false)}
            >
              Contact
            </Link>
          </div>
          <div className="py-6 text-center">
  <a
    href="https://apps.apple.com/app/coefficients/id6476617916"
    className="block bg-black text-white rounded-lg px-3 py-2.5 text-base font-semibold leading-7 hover:bg-gray-800"
    onClick={() => setMenuOpen(false)}
  >
    Download
  </a>
</div>
        </div>
      </div>
    </div>
  </div>
)}
    </header>
  );
};

export default Header;
